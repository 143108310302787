<template>
    <!-- 订单管理 -->
    <div class="box">
        <div class="header">
            <search :sonPlaceholder="placeholder" @sonsearchBut="searchBut" ref="searchRef"/>
            <datePicker @selector="selector" ref="datePickerRef"/>
        </div>
        <div class="content">
            <el-table 
                :data="orderList"
                ref="table" 
                height="100%" 
                v-loading="loading"
                element-loading-text="拼命加载中">
                <el-table-column
                    fixed
                    align="center"
                    type="index"
                    label="序号"
                    width="58">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="order_id"
                    label="订单id"
                    width="260">
                </el-table-column>
                <el-table-column
                    v-if="order_type=='mtcps'"
                    align="center"
                    prop="product_name"
                    label="门店名称">
                </el-table-column>
                <el-table-column
                    v-else
                    align="center"
                    prop="product_name"
                    label="商品名称">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="brand_name"
                    label="品牌名称"
                    width="160">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="create_time"
                    label="订单创建时间"
                    width="160">
                    <template slot-scope="scope">
                        {{scope.row.create_time | dateFormat}}
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="order_amount"
                    label="订单实付"
                    width="100">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="order_status"
                    label="订单状态"
                    width="160">
                    <template slot="header">
                        <el-dropdown trigger="click" >
                            <span class="el-dropdown-link">
                                订单状态<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item 
                                @click.native="statusTab(item.value)"
                                v-for="(item,index) in filters" 
                                :key="index">
                                    {{item.text}}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
                <el-table-column
                    v-if="order_type=='mtcps'|| order_type=='elmcps'"
                    align="center"
                    prop="finish_time"
                    label="订单完成时间"
                    width="160">
                    <template slot-scope="scope">
                        <span v-if="!scope.row.finish_time">-</span>
                        <span v-else>{{scope.row.finish_time | dateFormat}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="user"
                    label="下单用户id"
                    width="160">
                    <template slot-scope="scope">
                        <span v-if="!scope.row.user">-</span>
                        <span v-else>{{scope.row.user}}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" width="108" fixed="right" label="操作" v-if="order_type=='online'||order_type=='card'||order_type=='recharge'">
                    <template slot-scope="scope">
                        <el-button type="text" @click="clickDetails(scope.row.order_id,scope.row.order_type)">详情</el-button>
                        <!-- <el-button type="text">退款</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="footer">
             <paging :sonpagingData="pagingData" @sonHandleSizeChange="handleSizeChange" @sonHandleCurrentChange="handleCurrentChange"/>
        </div>
        <el-drawer :visible.sync="drawer"  :size="1136" :modal-append-to-body="true" @close="handleClose">
            <onlineOrder :sonorderDetails="orderDetails" v-if="order_type=='online'"></onlineOrder>
            <topUpOrder :sonorderDetails="orderDetails"  v-else-if="order_type=='recharge'"></topUpOrder>
            <cardOrder :sonorderDetails="orderDetails"  v-else-if="order_type=='card'"></cardOrder>
        </el-drawer>
    </div>
</template>
<script>
import search from '../../components/search.vue'
import paging from '../../components/paging.vue'
import datePicker from '../../components/datePicker.vue'
import onlineOrder from '../../components/onlineOrder.vue'
import topUpOrder from '../../components/topUpOrder.vue'
import cardOrder from '../../components/cardOrder.vue'
export default {
    components:{
        search,
        paging,
        datePicker,
        onlineOrder,
        topUpOrder,
        cardOrder
    },
    data(){
        return{
            status:'',
            search:'',//搜索值
            start_time:'',//开始时间
            end_time:'',//结束时间
            placeholder:'搜索订单ID/提交订单号',// 搜索提示
            // 分页数据
            pagingData:{
                page:1,
                max_page:15,
                count:0,
            },
            drawer:false, //订单详情的抽柜显示状态
            orderList:[{}],// 订单列表
            OrderID:0,// 查看的订单ID
            orderDetails:{},// 订单详情数据
            srcList:[],// 图片列表
            loading:false,// 加载状态
            filters:[{ text: '订单结算', value: '9' },{ text: '订单付款', value: '12' },{ text: '订单失效', value: '13' },{ text: '订单成功', value: '14' },{ text: '全部', value: '' }],
            order_type: 'elmcps',
        }
    },
    created(){
        this.getOrderList()
    },
    updated(){
        this.$refs.table.doLayout()
    },
    methods:{
        // 获取订单列表
        async getOrderList(){
            this.loading=true
            const {data:res} = await this.$http.get('c-admin/order_list/',{
                params:{
                    page:this.pagingData.page,
                    max_page:this.pagingData.max_page,
                    search:this.search,
                    start_time:this.start_time,
                    end_time:this.end_time,
                    order_type:this.order_type,
                    status:this.status
                }
            })
            if(res.status!==200)return this.$message({ showClose: true, message: res.message, type: 'error'})
            this.orderList=res.data.data
            this.pagingData.count=res.data.count
            this.loading=false
        },
        // 接收搜索组件传递过来的值
        searchBut(val){
            this.search=val
            this.getOrderList()
        },
        // 接收日期选择的数据
        selector(data){
            this.start_time=data.start_time
            this.end_time=data.end_time
            this.getOrderList()
        },
        // 监听pagesize改变
        handleSizeChange(val) {
            this.pagingData.max_page=val
            this.getOrderList()
        },
        // 监听页码值
        handleCurrentChange(val) {
            this.pagingData.page=val
            this.getOrderList()
        },
        // 查看订单详情
        async clickDetails(order_id,order_type){
             const{data:res} = await this.$http.get('c-admin/order_info/',{
                params:{
                    order_id:order_id,
                    order_type:order_type
                }
            })
            this.orderDetails = res.data;
            this.drawer = true;
        },
        //   筛选状态
        statusTab(val) {
            this.status=val
            this.getOrderList()
        },
        // 关闭抽柜回调
        handleClose(){
            // this.orderDetails={}
            this.srcList=[]
        }
    }
}
</script>
<style lang="less" scoped>
    .box{
        padding: 0 25px;
    }
    .header{
        .type-title{
            display: flex;
            align-items: center;
            white-space:nowrap;
        }
    }
    .content{
        padding-top: 20px;
        height: calc(100vh - 238px);
    }
    /deep/.el-drawer__body {
        overflow-y: scroll
    }
    /deep/.el-drawer__body::-webkit-scrollbar {
        width: 8px;
        height: 10px;
    }
    /* 设置滚动条的背景色和圆角 */
    /deep/.el-drawer__body::-webkit-scrollbar-thumb  {
        background-color: #BABEC7;
        border-radius: 8px;
    }
    /* 设置插槽的背景色和圆角 */
    /deep/.el-drawer__body::-webkit-scrollbar-track {
        background-color:rgb(255, 255, 255);
        border-radius:10px;
    }
    /deep/.el-drawer__header{
        margin-bottom: 0;
    }
    .el-dropdown{
        color: #909399;
    }
</style>